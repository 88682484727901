import React, { useEffect, useState } from "react";
import axios from "axios";
import { hostConfig } from "../../config.js";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Button, Select, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from "react-i18next";

const formatCurrency = (amount, currency) => {
    if (!amount) return '';

    try {
        const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount;

        switch(currency?.toUpperCase()) {
            case 'USD':
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(numericAmount / 100);

            case 'JPY':
                return new Intl.NumberFormat('ja-JP', {
                    style: 'currency',
                    currency: 'JPY',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                }).format(Math.round(numericAmount/100)); // No division for JPY

            case 'AUD':
                return new Intl.NumberFormat('en-AU', {
                    style: 'currency',
                    currency: 'AUD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(numericAmount / 100);

            case 'SGD':
                return new Intl.NumberFormat('en-SG', {
                    style: 'currency',
                    currency: 'SGD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(numericAmount / 100);

            default:
                return numericAmount.toString();
        }
    } catch (error) {
        return '';
    }
};

const PatientSchedule = () => {
    const [patientSchedules, setPatientSchedules] = useState([]);
    const [pastSessions, setPastSessions] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState('USD');
    const [exchangeRates, setExchangeRates] = useState({});
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        async function fetchData() {
            try {
                const [schedulesResponse, ratesResponse] = await Promise.all([
                    axios.get(`${hostConfig.API_BASE}/getpatientschedule`),
                    axios.get('https://api.exchangerate-api.com/v4/latest/USD')
                ]);

                const now = new Date();
                const sortedSchedules = schedulesResponse.data.sort((a, b) =>
                    new Date(a.booked_session_date_timestamp) - new Date(b.booked_session_date_timestamp)
                );

                const transformRow = (session) => ({
                    ...session,
                    hourly_rate: {
                        rate: session.hourly_rate,
                        currency: session.currency || 'USD'
                    },
                    meeting_info: { //adding this so that i can bundle the two information into one object to pass to the columns.  
                        meeting_token: session.meeting_token,
                        session_uuid: session.session_uuid
                    }

                });

                const upcomingSessions = sortedSchedules
                    .filter(session => new Date(session.booked_session_date_timestamp) > now)
                    .map(transformRow);

                const pastSessions = sortedSchedules
                    .filter(session => new Date(session.booked_session_date_timestamp) <= now)
                    .reverse()
                    .map(transformRow);

                setPatientSchedules(upcomingSessions);
                setPastSessions(pastSessions);
                setExchangeRates(ratesResponse.data.rates);
            } catch (error) {
                // Error handling
            }
        }
        fetchData();
    }, []);

    const handleJoinMeeting = (meeting_tokens, session_uuid) => {
        localStorage.setItem('session_uuid', session_uuid);
        navigate(`/session/room/${session_uuid}`, {
            state: {
                meeting_token: meeting_tokens,
                session_uuid: session_uuid,
            },
        });
    };

    const columns = [
        { 
            field: 'booked_session_date_timestamp', 
            headerName: 'Date and Time', 
            width: 200,
            valueGetter: (params) => {
                return moment(params).format('YYYY-MM-DD HH:mm');
            }
        },
        { 
            field: 'full_name', 
            headerName: 'Therapist Name', 
            width: 200 
        },
        { 
            field: 'hourly_rate', 
            headerName: 'Hourly Rate', 
            width: 200,
            valueGetter: (params) => {
                if (!params?.rate) return '-';
                
                const rate = params.rate;
                const originalCurrency = params.currency;
                
                if (selectedCurrency === originalCurrency) {
                    return formatCurrency(rate, originalCurrency);
                }
        
                if (exchangeRates[selectedCurrency] && exchangeRates[originalCurrency]) {
                    const usdAmount = originalCurrency === 'USD' ? 
                        rate / 100 : // Convert cents to dollars for USD
                        (rate / 100) / exchangeRates[originalCurrency]; // Convert cents to base currency first
                    const convertedAmount = usdAmount * exchangeRates[selectedCurrency] * 100; // Convert back to cents
                    return formatCurrency(convertedAmount, selectedCurrency);
                }
        
                return formatCurrency(rate, originalCurrency);
            },
            renderHeader: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <span>Hourly Rate</span>
                    <Select
                        value={selectedCurrency}
                        onChange={(e) => setSelectedCurrency(e.target.value)}
                        size="small"
                        style={{ height: '25px' }}
                    >
                        <MenuItem value="USD">USD</MenuItem>
                        <MenuItem value="JPY">JPY</MenuItem>
                        <MenuItem value="AUD">AUD</MenuItem>
                        <MenuItem value="SGD">SGD</MenuItem>
                    </Select>
                </div>
            ),
        },
        {
            field: 'meeting_token',
            headerName: 'Join Session',
            width: 150,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleJoinMeeting(params)}
                    disabled={!params?.meeting_token}
                >
                    Join
                </Button>
            ),
        }
    ];

    const upcomingColumns = [
        ...columns,
        {
            field: 'meeting_info',  // Changed from meeting_token to meeting_info
            headerName: 'Join Session',
            width: 150,
            renderCell: (params) => {
                console.log('Join button params:', {
                    params: params,
                    rowData: params.row,
                    meetingInfo: params.value
                });
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleJoinMeeting(params)}
                        disabled={!params?.meeting_token}
                    >
                        Join
                    </Button>
                );
            },
        },
    ];

    console.log('Patient Schedules with meeting_info:', patientSchedules.map(schedule => ({
        session_uuid: schedule.session_uuid,
        meeting_info: schedule.meeting_info
    })));
    
    console.log('Past Sessions with meeting_info:', pastSessions.map(schedule => ({
        session_uuid: schedule.session_uuid,
        meeting_info: schedule.meeting_info
    })));

    return (
        <Container maxWidth="md">
            <Typography variant="h4" gutterBottom>
                {patientSchedules.length > 0 ? 'Upcoming Sessions' : 'Sessions'}
            </Typography>
            {patientSchedules.length > 0 ? (
                <>
                    <div style={{ height: 400, width: '100%', marginBottom: '40px' }}>
                        <DataGrid
                            rows={patientSchedules}
                            columns={upcomingColumns}
                            pageSize={5}
                            rowsPerPageOptions={[5, 10, 20]}
                            getRowId={(row) => row.session_uuid || Math.random().toString()}
                        />
                    </div>

                    <Typography variant="h5" gutterBottom>Past Sessions</Typography>
                    {pastSessions.length > 0 ? (
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={pastSessions}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5, 10, 20]}
                                getRowId={(row) => row.session_uuid || Math.random().toString()}
                            />
                        </div>
                    ) : (
                        <Typography>No past sessions found.</Typography>
                    )}
                </>
            ) : (
                <div>
                    <Typography variant="h6" gutterBottom>No upcoming sessions scheduled.</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate('/searchtherapist')}
                        style={{ marginBottom: '20px' }}
                    >
                        Find a Therapist
                    </Button>
                    
                    <Typography variant="h5" gutterBottom>Past Sessions</Typography>
                    {pastSessions.length > 0 ? (
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={pastSessions}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5, 10, 20]}
                                getRowId={(row) => row.session_uuid || Math.random().toString()}
                            />
                        </div>
                    ) : (
                        <Typography>No past sessions found.</Typography>
                    )}
                </div>
            )}
        </Container>
    );
};

export default PatientSchedule;