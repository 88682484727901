import './App.css';
import React from 'react';
import TherapistLoginForm from './TherapistApps/LogIn/TherapistLogin';
import TherapistRegistrationForm from './TherapistApps/Registration/TherapistRegistration';
import PatientRegistrationForm from './PatientApps/PatientRegistration/PatientRegistration';
import PatientLoginForm from './PatientApps/PatientLogin/PatientLogin';
import PatientDashboard from './PatientApps/Account/PatientDashboard';
import AllNavBar from './PatientApps/Navbar';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import SearchPage from './PatientApps/SearchPage/SearchPage';
import FrontPage from './PatientApps/FrontPage/Frontpage';
import AlertDialog from './PatientApps/SearchPage/BookingCompletePage';
import "./index.css";
import BookingComplete from './PatientApps/SearchPage/BookingCompletePage';
import CloudinaryUploadWidget from './TherapistApps/Account/TherapistVideoUpload';
import VideoChatPage from './TherapistApps/VideoChat/VideoPageApp';
import WeekView from './TherapistApps/TherapistDashboard/TherapistScheduler/WeekView';
import Therapist_ClientList from './TherapistApps/TherapistDashboard/ClientList/ClientList';
import TherapistDashboard from './TherapistApps/TherapistDashboard/TherapistDashboard';
import ReviewCarousel from './PatientApps/Reviews/ReviewsCards';
import LineCallback from './TherapistApps/Registration/LineCallback';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import PatientVideoPage from './PatientApps/Account/PatientVideo/PatientVideoPage';
import Sender from './TherapistApps/VideoChat/CalendarRequest/RequestSender';
import Receiver from './PatientApps/Account/PatientVideo/CalendarRequestPopup/CalendarReceiver';
import CompanyOverview from './PatientApps/CompanyPage/CompanyPage';
import TermsofUse from './PatientApps/CompanyPage/termsofuse';
import LegalDisclosure from './PatientApps/FrontPage/ServiceAgreement/Serviceagreement';
import { VideoControls } from './TherapistApps/VideoChat/VideoControl/VideoControl';
import ChargeButton from './TherapistApps/TherapistDashboard/History/patientcharge';
import SaveCCInfo from './PatientApps/Account/RegisterCardInfo';
import TherapistPaymentApp from './TherapistApps/TherapistDashboard/Payments/payments';
import BookingsCalendarModal from './PatientApps/SearchPage/CalendarModal';
import VideoChatPagetest from './TherapistApps/VideoChat/videoapptest';
import PatientVideoV2 from './PatientApps/Account/PatientVideo/PatientVideoPageV2';
import AdminLogin from './TherapistApps/Admin/AdminLogin';
import PatientResetPassword from './PatientApps/PatientLogin/ResetPassword';
import TherapistResetPassword from './TherapistApps/LogIn/ResestPassword';
import LandingPage from './TherapistApps/HomePage/FrontPage';

const getIsTherapistDomain = () => {
  const hostname = window.location.hostname;
  
  // Check if running locally
  if (hostname === 'localhost' || hostname === '127.0.0.1') {
    // You can set this based on a route prefix or environment variable
    // For example, checking if the path starts with /therapist
    return window.location.pathname.startsWith('/therapist');
  }
  
  // Production environment check
  return hostname.startsWith('therapists.');
};

const App = () => {
  const isTherapistDomain = getIsTherapistDomain();

  React.useEffect(() => {
    // Only run redirects in production environment
    if (window.location.hostname !== 'localhost' ) {
      const currentPath = window.location.pathname;
      
      if (isTherapistDomain) {
        if (currentPath.includes('/patient')) {
          window.location.href = `https://www.ascreforme.com${currentPath}`;
        }
      } else {
        if (currentPath.includes('/therapist')) {
          window.location.href = `https://therapists.ascreforme.com${currentPath}`;
        }
      }
    }
  }, []);

  // Common routes that appear on both patient and therapist domains
  const commonRoutes = [
    <Route key="callback" path="/callback" element={<LineCallback/>} />,
    <Route key="company" path="/companyinformation" element={<CompanyOverview/>} />,
    <Route key="terms" path="/termsofuse" element={<TermsofUse/>} />,
    <Route key="disclosure" path="/disclosure" element={<LegalDisclosure/>} />
  ];

  // Therapist-specific routes
  const therapistRoutes = [
    <Route key="therapist-home" exact path='/therapistlp' element={<LandingPage/>} />,
    <Route key="therapist-login" exact path='/login' element={<TherapistLoginForm/>} />,
    <Route key="therapist-register" exact path='/register' element={<TherapistRegistrationForm/>} />,
    <Route key="therapist-dashboard" exact path='/dashboard' element={<TherapistDashboard/>} />,
    <Route key="therapist-scheduler" exact path='/testing/scheduler' element={<WeekView/>} />,
    <Route key="therapist-clients" exact path='/testing/clientlist' element={<Therapist_ClientList/>} />,
    <Route key="therapist-video" path="/session/videochat/:session_uuid" element={<VideoChatPagetest/>} />,
    <Route key="therapist-calendar" path="/testing/calendarrequest" element={<Sender/>} />,
    <Route key="video-control" path="/videocontrol" element={<VideoControls/>} />,
    <Route key="charge" path="/testing/chargebutton" element={<ChargeButton/>} />,
    <Route key="payment" path="/therapistpayment" element={<TherapistPaymentApp/>} />,
    <Route key="video-test" path="/testing/videotestpage" element={<VideoChatPagetest/>} />,
    <Route key="video-chat" path="/testingvideochat" element={<VideoChatPagetest/>} />,
    <Route key="admin" path="/testing/adminlogin" element={<AdminLogin/>} />,
    <Route key="reset-pw" path="/therapistpwreset" element={<TherapistResetPassword/>} />
  ];

  // Patient-specific routes
  const patientRoutes = [
    <Route key="patient-home" exact path='/' element={<FrontPage/>} />,
    <Route key="search" exact path="/searchtherapist" element={<SearchPage/>} />,
    <Route key="patient-login" exact path='/patientlogin' element={<PatientLoginForm/>} />,
    <Route key="patient-register" exact path='/patientregister' element={<PatientRegistrationForm/>} />,
    <Route key="patient-dashboard" exact path='/patientdashboard' element={<PatientDashboard/>} />,
    <Route key="booking-complete" exact path='/bookingcomplete' element={<BookingComplete/>} />,
    <Route key="reviews" exact path='/reviews' element={<ReviewCarousel/>} />,
    <Route key="patient-video" path="/patient/videochat" element={<PatientVideoPage/>} />,
    <Route key="patient-room" path="/session/room/:session_uuid" element={<PatientVideoV2/>} />,
    <Route key="patient-receive" path="/patientreceive" element={<Receiver/>} />,
    <Route key="patient-cc" path="/patientCC" element={<SaveCCInfo/>} />,
    <Route key="calendar-modal" path="/calendarmodal" element={<BookingsCalendarModal/>} />,
    <Route key="patient-video-v2" path="/patientvideoV2" element={<PatientVideoV2/>} />,
    <Route key="patient-reset" path="/patientpwreset" element={<PatientResetPassword/>} />
  ];

  return (
    <div className="App">
      <Router>
        <AllNavBar />
        <Routes>
          {isTherapistDomain ? [...therapistRoutes, ...commonRoutes] : [...patientRoutes, ...commonRoutes]}
        </Routes>
      </Router>
    </div>
  );
};

export default App;